import React from "react";
import { NavLink } from "react-router-dom";
import LogoutButton from "../button/LogoutButton";
import s from "./Header.module.scss";

const Header = () => {
  return (
    <header className={s.header}>
      <NavLink exact="true" to="/" className={s.header_logo}>
        QuickInvoices
      </NavLink>
      <div className={s.header_nav}>
        <nav>
          <NavLink
            exact="true"
            to="/"
            className={({ isActive }) => (isActive ? s.active : undefined)}>
            List
          </NavLink>
          <NavLink to="/form" className={({ isActive }) => (isActive ? s.active : undefined)}>
            Form
          </NavLink>
        </nav>
        <div className={s.header_nav_logout}>
          <LogoutButton />
        </div>
      </div>
    </header>
  );
};

export default Header;

export const COLUMNS = [
  {
    title: "Invoice Number",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    width: 90,
    fixed: "left",
  },
  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    key: "invoiceDate",
    width: 110,
  },
  {
    title: "Payment Due",
    dataIndex: "paymentDue",
    key: "paymentDue",
    width: 100,
  },
  {
    title: "VAT ID",
    dataIndex: "vatId",
    key: "vatId",
    width: 110,
  },
  {
    title: "Advertiser",
    dataIndex: "advertiser",
    key: "advertiser",
    width: 110,
  },
  {
    title: "Bill to",
    dataIndex: "billTo",
    key: "billTo",
    width: 300,
  },
  {
    title: "Recipient",
    dataIndex: "recipient",
    key: "recipient",
    width: 300,
  },
  {
    title: "Service",
    dataIndex: "service",
    key: "service",
    width: 150,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 110,
  },
  {
    title: "VAT",
    dataIndex: "vat",
    key: "vat",
    width: 80,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    width: 100,
  },
  {
    title: "Payment method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    width: 100,
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    width: 300,
  },
];

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Input, Button, Space, Alert } from "antd";
import { COLUMNS } from "../../data/columns";
import { API } from "../../utils/axios";

import s from "./FormInvoice.module.scss";

const FormInvoice = () => {
  const [form] = Form.useForm();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(false);
  const [alert, setAlert] = useState(false);
  const [generationSwitch, setGenerationSwitch] = useState(false);

  const onFinish = async (data) => {
    if (generationSwitch) {
      setLoadingGeneration(true);
      try {
        const token = await getAccessTokenSilently();
        await API.post("/pdf", [data], {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);

          a.href = url;
          a.download = `${data.invoiceNumber}-${data.advertiser.replace(
            / /g,
            "_",
          )}-${data.paymentMethod.replace(/ /g, "_")}.pdf`;

          a.click();
          a.remove();
          setLoadingGeneration(false);
        });
      } catch (error) {
        console.log(error);
        setLoadingGeneration(false);
      }
    } else {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        await API.post("/", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          console.log(response.status);
          setLoading(false);
          setAlert(true);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className={s.form_invoice}>
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        {COLUMNS.map(({ title, dataIndex }, item) => {
          return (
            <Form.Item
              key={item}
              label={title}
              name={dataIndex}
              tooltip={`Please input ${title}`}
              rules={[{ required: true, message: `Please input ${title}!` }]}>
              <Input placeholder={title} />
            </Form.Item>
          );
        })}

        <Space wrap>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setGenerationSwitch(false)}
            loading={loading}>
            Save to table
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setGenerationSwitch(true)}
            loading={loadingGeneration}>
            Generate PDF
          </Button>

          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>

          {alert ? (
            <div className={s.form_invoice_alert}>
              <Alert message="Invoice successfully saved!" type="success" />
            </div>
          ) : (
            ""
          )}
        </Space>
      </Form>
    </div>
  );
};

export default FormInvoice;

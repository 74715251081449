import React from "react";
import Header from "../components/Header";
import ListInvoice from "../components/ListInvoice";

const ListInvoicePage = () => {
  return (
    <>
      <Header />
      <ListInvoice />
    </>
  );
};

export default ListInvoicePage;

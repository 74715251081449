import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import ListInvoicePage from "./pages/ListInvoicePage";
import FormInvoicePage from "./pages/FormInvoicePage";
import AuthorizationPage from "./pages/AuthorizationPage";

export const useRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  } else {
    if (isAuthenticated) {
      return (
        <Routes>
          <Route path="/form" element={<FormInvoicePage />} />
          <Route path="/" element={<ListInvoicePage />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" exact element={<AuthorizationPage />} />
        </Routes>
      );
    }
  }
};

import React from "react";
import Header from "../components/Header";
import FormInvoice from "../components/FormInvoice";

const FormInvoicePage = () => {
  return (
    <>
      <Header />
      <FormInvoice />
    </>
  );
};

export default FormInvoicePage;

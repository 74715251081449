import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API } from "../../utils/axios";
import { COLUMNS } from "../../data/columns";
import { Table, FloatButton } from "antd";

import s from "./ListInvoice.module.scss";

const ListInvoice = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getList = async () => {
      try {
        const token = await getAccessTokenSilently();
        await API.get("/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          response.data.shift();
          setData(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getList();
  }, [getAccessTokenSilently]);

  const formData = data.map((item, index) => {
    return {
      key: index,
      invoiceNumber: item[0],
      invoiceDate: item[1],
      paymentDue: item[2],
      vatId: item[3],
      advertiser: item[4],
      billTo: item[5],
      recipient: item[6],
      service: item[7],
      amount: item[8],
      vat: item[9],
      currency: item[10],
      paymentMethod: item[11],
      details: item[12],
    };
  });

  const handlerRun = async () => {
    const selectedInvoices = selectedRowKeys.map((item) => formData[item]);
    const token = await getAccessTokenSilently();

    await API.post("/pdf", selectedInvoices, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);

      a.href = url;

      if (selectedInvoices.length === 1) {
        a.download = `${
          selectedInvoices[0].invoiceNumber
        }-${selectedInvoices[0].advertiser.replace(
          / /g,
          "_",
        )}-${selectedInvoices[0].paymentMethod.replace(/ /g, "_")}.pdf`;
      } else {
        const today = new Date();

        a.download = `${today.toLocaleString().replace(/, /g, "_")}.zip`;
      }

      a.click();
      a.remove();
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className={s.list_invoice}>
      <div className={s.list_invoice_btn}>
        <FloatButton
          type="primary"
          onClick={handlerRun}
          disabled={!hasSelected}
          style={{ left: 30, bottom: 30 }}
          tooltip={
            <span className={s.list_invoice_btn_text}>
              {hasSelected ? `${selectedRowKeys.length} selected` : "0 selected"}
            </span>
          }
        />
      </div>
      <Table
        pagination={false}
        rowSelection={rowSelection}
        columns={COLUMNS}
        dataSource={formData}
        scroll={{
          x: 1992,
          y: "calc(100vh - 86px)",
        }}
      />
    </div>
  );
};

export default ListInvoice;

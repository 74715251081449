import React from "react";
import s from "./Authorization.module.scss";
import LoginButton from "../button/LoginButton";

const Authorization = () => {
  return (
    <div className={s.authorization}>
      <h1 className={s.authorization_title}>QuickInvoices</h1>
      <LoginButton />
    </div>
  );
};

export default Authorization;
